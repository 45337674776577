@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.noto-sans-thin {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
.noto-sans-extralight {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.noto-sans-light {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.noto-sans-normal {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.noto-sans-medium {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.noto-sans-semibold {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.noto-sans-bold {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.noto-sans-extrabold {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.noto-sans-black {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
