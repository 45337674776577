@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.scss';
@import './tailwind.scss';

* {
  font-family: Noto Sans;
}

img {
  user-select: none;
}

.noscrollbar {
  scrollbar-width: none;
  overflow-y: scroll;
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: auto;
}
.xscrollable {
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-gutter: auto;
}
.scrollable::-webkit-scrollbar {
  width: 8px;
  overflow-y: scroll;
}
.scrollable::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-[#e1e1e1] dark:bg-slate-600;
}
.scrollable::-webkit-scrollbar-track {
  margin: 10px 0;
  background-color: transparent;
}
.xscrollable::-webkit-scrollbar {
  height: 4px;
  overflow-y: scroll;
}
.xscrollable::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-[#e1e1e1] dark:bg-slate-600;
}
.xscrollable::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 0 20px;
}

:root {
  @apply bg-surfaceLight dark:bg-surfaceLighter;
}

#chat-window,
#broadcastChat-window {
  background-image: url('../../shared/assets/images/messageBg.png');
}

:root.dark #chat-window,
:root.dark #broadcastChat-window {
  background-image: url('../../shared/assets/images/darkMessageBg.jpg');
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #e1e1e6 inset;
}

::placeholder {
  user-select: none;
}

input {
  transition: background-color 0.1s ease-out;
}

input:focus {
  outline: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
