.activeLink {
  @apply bg-activeLinkLight dark:bg-activeLinkDark;
}
.inactiveLink {
  @apply bg-surfaceLight hover:bg-activeLinkLight dark:bg-surfaceLighter dark:hover:bg-activeLinkDark;
}

@layer components {
  .pageBlock {
    @apply scrollable h-full items-center rounded-2xl bg-white p-4 shadow-sm dark:bg-surfaceDark;
  }
  .centeredBlock {
    @apply flex h-full w-full items-center justify-center rounded-2xl bg-white shadow-sm dark:bg-surfaceDark;
  }

  .input {
    @apply rounded-xl bg-surfaceLight px-4 py-2 font-medium duration-150 focus:bg-activeLinkLight dark:bg-surfaceLighter dark:text-white dark:focus:bg-activeLinkDark;
  }
  .cellInput {
    @apply noscrollbar rounded-lg bg-surfaceLight px-2 font-medium duration-150 focus:bg-activeLinkLight focus:outline-none dark:bg-surfaceLighter dark:text-white dark:focus:bg-activeLinkDark;
  }
  .phoneInput {
    @apply rounded-xl bg-white px-4 py-2 font-medium duration-150 focus:bg-activeLinkLight focus:outline-slate-300 dark:bg-surfaceDark dark:text-white dark:focus:bg-activeLinkDark dark:focus:outline-slate-600;
  }

  .hoverable {
    @apply duration-150 hover:bg-activeLinkLight dark:hover:bg-activeLinkDark;
  }

  .button {
    @apply flex select-none items-center justify-center rounded-2xl px-3 py-2 text-sm font-semibold duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .littleButton {
    @apply flex select-none items-center justify-center rounded-lg text-sm font-medium duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .iconButton {
    @apply flex select-none items-center justify-center rounded-2xl p-2 font-semibold duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .dangerIconButton {
    @apply flex select-none items-center justify-center rounded-2xl p-2 font-semibold duration-150 hover:bg-[#fee2e2] dark:text-white dark:hover:bg-[#8b5454];
  }
  .broadcastButton {
    @apply flex w-full select-none items-center justify-start rounded-2xl p-3 text-sm font-medium duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .broadcastChatButton {
    @apply flex w-fit cursor-pointer select-none items-center justify-center rounded-xl bg-surfaceLight px-4 py-1 font-semibold duration-150 hover:bg-activeLinkLight focus:outline-none dark:bg-surfaceLighter dark:text-white dark:hover:bg-activeLinkDark;
  }

  .baseFilter {
    @apply flex h-10 select-none items-center justify-center rounded-xl px-3 py-1 text-sm duration-150;
  }

  .message {
    @apply place-self-start rounded-br-xl rounded-tl-xl rounded-tr-xl bg-white dark:bg-surfaceLighter;
  }
  .myMessage {
    @apply place-self-end rounded-bl-xl rounded-tl-xl rounded-tr-xl bg-[#d9fdd3] dark:bg-[#005c4b];
  }
  .audioMessage {
    @apply place-self-start rounded-br-xl rounded-tl-xl rounded-tr-xl bg-white dark:bg-surfaceLighter;
  }
  .myAudioMessage {
    @apply place-self-end rounded-bl-xl rounded-tl-xl rounded-tr-xl bg-[#d9fdd3] dark:bg-[#005c4b];
  }
}
